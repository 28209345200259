<template>
  <div id="layers-panel" class="layers-panel panel-wide" v-if="active">
    <div class="scenario-selection-container d-flex flex-row align-items-center no-top-margin-all-descendants" v-if="viewerMode == 'Viewer' || sceneViewCompositionMode">
      <b-avatar class='ml-2' v-if='isSplitView'>{{ avatarLetter }}</b-avatar>
      <h3 class="scenario">Scenario</h3>
      <b-dropdown size="sm" variant="outline-primary bg-white border-white text-dark" :class="['mx-2', 'scenario-dropdown']" menu-class="scenario-dropdown-menu" v-if="isNotOTPUserAndInboundScenario">
        <template #button-content>
          <div :class="['dd']">
            <p v-b-tooltip.hover="{title: `${selectedScenario}`, placement: 'bottomright'}">{{ selectedScenario }}</p>
          </div>
        </template>
        <b-dropdown-item-button
          class="dropdown-button ml-1"
          variant="dark"
          v-for="simulationLabel in updatedSimulationLabels"
          :key="simulationLabel.id"
          @click="setScenario(simulationLabel.label, simulationLabel.configurationId)"
          :disabled='!simulationLoaded(simulationLabel)'>
          <b-spinner v-if='!simulationLoaded(simulationLabel)' small variant='primary'></b-spinner>
          <div 
            class="d-flex flex-row" 
            style="margin-left: -1.5rem;" 
            v-if=isSelected(simulationLabel)>
              <b-icon-check2 class="mr-1" /><span><b>{{ `${simulationLabel.label}`}}</b></span> 
          </div>
          <div v-else>{{ `${simulationLabel.label}` }} </div>
        </b-dropdown-item-button>
        <b-dropdown-divider v-if="!isDemoProject && canAddScenario && (isProjectInCompany || companyIsRWDI) && !loggedInUser.is_otp_user"></b-dropdown-divider>
        <b-dropdown-item-button v-if="!isDemoProject && viewerMode != 'Insights' && canAddScenario && (isProjectInCompany || companyIsRWDI) && !loggedInUser.is_otp_user" class="dropdown-button" @click="$emit('add-scenario')">+ Add Scenario</b-dropdown-item-button>
      </b-dropdown>
    </div>
    <div ref="layers-panel-content" class="layers-panel-content no-top-margin-all-descendants" v-show="isNotOTPUserAndInboundScenario">
      <form v-if="(!isSimulationInbound || createScenarioStep !== 0) && viewerMode === 'Viewer' || sceneViewCompositionMode">  <!-- geometry section -->
        <section class="layers-section">
          <h5 class="mb-3">Geometry</h5>
          <div class="layers-group">
            <input-button v-for="(layer, index) in this.geometryLayers"
            theme='checkbox'
            :key="`${layer.identifier}-${index}`"
            :layersetAssets="layer.layerSetAssets"
            :layer="layer"
            :uuid="`${layer.identifier}-${index}-${rank}`"
            :isDisabled="layer.isLoading"
            :isLoading="layer.isLoading"
            :isVisible="layer.isVisible"
            :hideDeleteButton="true"
            :complete-label="layer.completeLabel"
            :abbreviated-label="layer.abbreviatedLabel"
            @toggle="toggleGeometryLayerSet(layer)"
            @toggleWireframe="toggleWireframe"/>

          </div>
        </section>
        <hr class="mt-4 mb-2"/>
      </form>
      <div v-show="(isSimulationInbound || submittedSimulationHasErrors) && viewerMode == 'Viewer'"> <!-- geometry upload form -->
        <file-uploader
        :project-id="projectId"
        :form-values="this.theFormValues"
        :configuration-id="this.selectedConfigurationId"
        :study-id="studyId"
        :simulation-id="simulationId"
        :key="componentKey"
        :geometry-layers="this.geometryLayers"
        @file-uploaded="fileUploaded"
        @file-removed="fileRemoved"
        />
      </div>
      <div class="layers-section mt-3" v-show="!(isSimulationInbound || submittedSimulationHasErrors) && active">   <!-- results panel -->
        <form class="no-top-margin-all-descendants">   <!-- show regular layers panel -->
          <!-- Project asset section (viewerMode == 'Viewer') -->
          <section v-if="viewerMode === 'Viewer' || sceneViewCompositionMode">
            <b-tabs justified active-nav-item-class="font-weight-bold text-dark no-underline" content-class="mt-2" :pills="numberOfTabs == 1"> <!-- use pills mode when there's only 1 tab to hide the border and make it appear as though there's no tab control here -->
              <b-tab active title-link-class="pb-0 bg-transparent" >
                <template #title>
                  <div class="d-flex flex-row">
                    <span :class="[numberOfTabs == 1 ? 'layers-header-single-tab' : 'layers-header']">Layers</span>
                    <b-icon-info-circle class="ml-2" id="layer-info-icon" variant=secondary v-if="allLayerSets.length > 0"/>
                    <b-tooltip target="layer-info-icon" triggers="hover click" v-if="allLayerSets.length > 0">
                      Click the +Add button to browse your simulation results.  Adding multiple layers to the panel allows you to quickly toggle between them.
                    </b-tooltip>
                  </div>
                </template>

                <div class="d-flex justify-content-end">
                  <b-button title="Clear selected layers" v-if="layerSets.length > 0" variant="outline-secondary" :class="[numberOfTabs == 1 ? 'mr-3 add-button-single-tab' : 'mr-3 add-button']" @click="clearAllLayers"><div class="d-flex flex-row align-items-center"><b-icon-x-circle class="mr-tiny"/> Clear</div></b-button>
                  <b-button title="Add simulation results to the viewer" v-if="allLayerSets != null && allLayerSets.length > 0" variant="outline-primary" :class="[numberOfTabs == 1 ? 'add-button-single-tab' : 'add-button']" @click="showModal()"><div class="d-flex flex-row align-items-center"><b-icon-plus-circle class="mr-tiny"/>Add</div></b-button>
                </div>
                <div class="d-flex justify-content-center" v-if="this.simulationIsLoading || !this.assetUploadUrlAvailable">
                  <b-spinner variant="primary" label="loading" />
                </div>
                <div class="assets-group mt-0" v-if="horizontalPlots != null && horizontalPlots.length > 0">
                  <div class="category-title justify-content-between mb-2">
                    <div class="horizontal-category">
                      <h3> Horizontal </h3>
                    </div>
                    <presentation-plane-dropdown v-if="presentationPlanes"
                      layerType="Horizontal"
                      :presentationPlanes="presentationPlanes['Horizontal']"
                      @presentation-plane-toggled="onPresentationPlaneToggled"
                      @clear-all-presentation-planes="onClearAllPresentationPlanes"
                      @select-all-presentation-planes="onSelectAllPresentationPlanes"
                      :isDisabled="resultLayerOfTypeLoading('Horizontal')"
                    />
                  </div>

                  <input-button v-for="(layer, index) in horizontalPlots"
                  theme='hybrid-radio-checkbox'
                  :key="layer.identifier"
                  :layersetAssets="layer.layerSetAssets"
                  :layer="layer"
                  :uuid="`${layer.identifier}-${index}-${rank}`"
                  :isDisabled="resultLayerOfTypeLoading(layer.layerType)"
                  :isLoading="layer.isLoading"
                  :isVisible="layer.isVisible"
                  :complete-label="layer.completeLabel"
                  :abbreviated-label="layer.abbreviatedLabel"
                  @toggle="radioToggleResultLayerSet(layer)"
                  @delete="remove(layer)"
                  />
                </div>
                <div class="assets-group mt-3" v-if="verticalPlots != null && verticalPlots.length > 0">
                  <div class="category-title justify-content-between  mb-2">
                    <div class="vertical-category">
                      <h3> Vertical </h3>
                    </div>
                    <presentation-plane-dropdown v-if="presentationPlanes"
                      layerType="Vertical"
                      :presentationPlanes="presentationPlanes['Vertical']"
                      @presentation-plane-toggled="onPresentationPlaneToggled"
                      @clear-all-presentation-planes="onClearAllPresentationPlanes"
                      @select-all-presentation-planes="onSelectAllPresentationPlanes"
                      :isDisabled="resultLayerOfTypeLoading('Vertical')"
                    />
                  </div>

                  <input-button v-for="(layer, index) in verticalPlots"
                  theme='hybrid-radio-checkbox'
                  :key="layer.identifier"
                  :layersetAssets="layer.layerSetAssets"
                  :layer="layer"
                  :uuid="`${layer.identifier}-${index}-${rank}`"
                  :isDisabled="resultLayerOfTypeLoading(layer.layerType)"
                  :isLoading="layer.isLoading"
                  :isVisible="layer.isVisible"
                  :complete-label="layer.completeLabel"
                  :abbreviated-label="layer.abbreviatedLabel"
                  @toggle="radioToggleResultLayerSet(layer)"
                  @delete="remove(layer)"
                  />
                </div>

                <div class="assets-group mt-3" v-if="volumetricPlots != null && volumetricPlots.length > 0">
                  <div class="category-title justify-content-between mb-2">
                    <div class="volumetric-category">
                      <h3> Volumetric </h3>
                    </div>
                    <presentation-plane-dropdown v-if="presentationPlanes"
                      layerType="Volumetric"
                      :presentationPlanes="presentationPlanes['Volumetric']"
                      @presentation-plane-toggled="onPresentationPlaneToggled"
                      @clear-all-presentation-planes="onClearAllPresentationPlanes"
                      @select-all-presentation-planes="onSelectAllPresentationPlanes"
                      :isDisabled="resultLayerOfTypeLoading('Volumetric')"
                    />
                  </div>

                  <input-button v-for="(layer, index) in volumetricPlots"
                  theme='hybrid-radio-checkbox'
                  :key="layer.identifier"
                  :layersetAssets="layer.layerSetAssets"
                  :layer="layer"
                  :uuid="`${rank}-${layer.identifier}-${index}`"
                  :isDisabled="resultLayerOfTypeLoading(layer.layerType)"
                  :isLoading="layer.isLoading"
                  :isVisible="layer.isVisible"
                  :complete-label="layer.completeLabel"
                  :abbreviated-label="layer.abbreviatedLabel"
                  @toggle="radioToggleResultLayerSet(layer)"
                  @delete="remove(layer)"
                  />
                </div>
              </b-tab>
              <b-tab v-if="canDownloadCombinedData && !isSplitView" title="Data" title-link-class="pb-0 bg-transparent">
                <data-asset class="mt-2" v-for="data in dataAssets"
                :key="data.identifier"
                :assetObj="data.simulationResult" />
              </b-tab>
            </b-tabs>
          </section>

          <modal :show-modal="modalVisible" @close-modal="modalVisible = false" class="layers-selection" content-class="no-top-margin-all-descendants">
            <template v-slot:main-content>
              <layer-picker
                @layers-selected="onLayersSelected()"
                @close-form="modalVisible = false"
                :selectedLayerSets="selectedLayerSets"
                :globalFilter='globalFilter'
                :layerSetsMatchingGlobalFilter='layerSetsMatchingGlobalFilter'
                :assetsMatchingGlobalFilter='assetsMatchingGlobalFilter'
                :layerPickerInputs='layerPickerInputs'
                :appliedFilterList='appliedFilterList'
                @setFilterValue="setFilterValue"
                @clearGlobalFilter="clearGlobalFilter"/>
            </template>
          </modal>
        </form>
      </div>
    </div>
    <a v-if="(!isSplitView || rank === 'secondary') && isNotOTPUserAndInboundScenario" class="toggle-sidebar-button" role="button" title="Toggle sidebar" type="button" @click="toggleNav()">
      <b-icon icon="chevron-left"></b-icon>
    </a>
  </div>
  <div id="closed-layers-panel" class="closed-layers-panel" @click="toggleNav()" v-else>
    <b-icon icon="chevron-right"></b-icon>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InputButton from './InputButton';
import DataAsset from './DataAsset';
import LayerPicker from './LayerPicker';
import { PresentationPlane } from '@/models/presentationPlane';
import PresentationPlaneDropdown from './PresentationPlaneDropdown';
import FileUploader from './FileUploader';
import { Asset } from '@/models/asset';
import {
  ADD_SCENARIO
} from '@/constants/permissions';
import {
  Modal,
} from 'rwdi-widgets';
import _ from 'lodash';
import { sortConfigurationLabels } from '@/utilities/order-by';

export default {
  name: 'LayersPanel',
  components: {
    InputButton,
    DataAsset,
    LayerPicker,
    PresentationPlaneDropdown,
    FileUploader,
    Modal,
  },
  props: {
    viewId: {
      required: true
    },
    simulation: {
      required: true
    },
    geometryAssets: {
      required: true
    },
    geometryLayerSets: {
      required: true
    },
    showByDefaultLayerSets: {
      required: true
    },
    savedLayers: {
      required: true
    },
    allLayerSets: {
      required: true
    },
    selectedLayerSets: {
      required: true
    },
    defaultAssets: {
      required: true
    },
    globalFilter: {
      required: true
    },
    layerSetsMatchingGlobalFilter: {
      required: true
    },
    assetsMatchingGlobalFilter: {
      required: true
    },
    rank: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    layerPickerInputs: {
      required: true
    },
    appliedFilterList: {
      required: true
    },
    multipleScreenshots: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      modalVisible: false,  //open/close the layer picker modal window
      geometryLayers: [],  //assets of type 'geometry' from the store, grouped into layersets (though there is only 1 asset in each layerset)
      layerSets: [],   // layer sets from the simulationasset store that were selected in the layer picker (or added at load via the 'show_by_default' flag)
      presentationPlanes: null, //a dict {'type', [PresentationPlane]} of the different presentation planes available for the selected configuration keyed on layer type,
      selectedScenario: null,
      projectId: Number(this.$route?.params?.id),
      studyId: Number(this.$route?.params?.study),
      newFiles: {},
      theFormValues: {},
      componentKey: 0
    };
  },
  async mounted() {
    this.getInitialSimulation();
    this.clearAddedLayerSets();  //make sure the list of added layer sets is empty
  },
  computed: {
    isNotOTPUserAndInboundScenario() {
      return !(this.loggedInUser?.is_otp_user && this.isSimulationInbound);
    },
    insightLayers() {
      if(!this.sceneViewCompositionMode)
        return [];

      const activeLayers = this.firstSceneView?.active_layers;  //active layers is a list of assets that should be displayed with the Insight
      if (activeLayers && this.allLayersetsForSelectedInsightSimulation.length > 0) {
        let layerSetsForCurrentInsight = [];
        for (let i = 0; i < activeLayers.length; i++) {
          let layerSetForAsset = this.allLayersetsForSelectedInsightSimulation.filter(layer => layer.layerSetAssets.some(asset => asset.simulationResult.id == activeLayers[i].id));
          let existingEntry = layerSetsForCurrentInsight.find(layer => layer.identifier == layerSetForAsset.identifier);
          if (!existingEntry) {
            layerSetsForCurrentInsight = layerSetsForCurrentInsight.concat(layerSetForAsset);
          }
        }

        //sub-layers that are turned off for the layersets of the current insight already have their `isVisible` flag set to false
        //as the insightLayer computer in InsightTitle.vue does this and the results are reflected in `this.allLayersetsForSelectedInsightSimulation`

        return layerSetsForCurrentInsight;
      }
      return [];
    },
    updatedSimulationLabels() {
      let labels;

      if (this.selectedSimulationLabels.length == 0)
        labels = this.simulationLabels;
      else
        labels = this.selectedSimulationLabels.filter(simulationLabel => this.simulationLabels.map(a => a.configurationId).includes(simulationLabel.configurationId));

      if (labels) {
        return labels.sort( sortConfigurationLabels );
      } else {
        return undefined;
      }
    },
    currentInsight() {
      if (this.currentInsights && this.currentInsightIndex > this.currentInsights.length - 1) {
        return this.currentInsights.at(this.currentInsights.length - 1);
      }
      return this.currentInsights?.at(this.currentInsightIndex);
    },
    currentInsightIsSceneView() {
      return this.currentInsight?.sceneview_set.length > 0;
    },
    numberOfTabs() {
      let tabCount = 1;  //there's always a layers tab
      if (this.canDownloadCombinedData)
        tabCount+=1;

      return tabCount;
    },
    isSplitView() {
      return !!this.$route.query?.secondary;
    },
    avatarLetter() {
      if(this.rank === 'primary') return 'A';
      else if (this.rank === 'secondary') return 'B';
      else return null;
    },
    simulationId() {
      return this.simulation?.id;
    },
    selectedConfigurationId() {
      return this.$store.getters['project/simulationAsset/configurationId'];
    },
    submittedFormValues() {
      return this.$store.getters['project/submittedFormValues']?.newFormValues || this.formValues;
    },
    formValues() {
      return this.$store.getters['project/createdScenarioFormValues'];
    },
    horizontalPlots() {
      let horiz_plots = this.layerSets;
      if(this.sceneViewCompositionMode && this.insightLayers.length > 0 && this.insightLayers[0].layerSetAssets[0].simulationResult.scenario === this.selectedScenario) {
        horiz_plots = this.insightLayers.concat(horiz_plots);
        horiz_plots = horiz_plots.filter((layer, i, arr) => arr.map(l => l.identifier).indexOf(layer.identifier) === i);
      }

      return horiz_plots.filter(layer => layer.layerType === 'Horizontal');
    },
    verticalPlots() {
      let vert_plots = this.layerSets;
      if(this.sceneViewCompositionMode && this.insightLayers.length > 0 && this.insightLayers[0].layerSetAssets[0].simulationResult.scenario === this.selectedScenario) {
        vert_plots = this.insightLayers.concat(vert_plots);
        vert_plots = vert_plots.filter((layer, i, arr) => arr.map(l => l.identifier).indexOf(layer.identifier) === i);
      }

      return vert_plots.filter(layer => layer.layerType === 'Vertical');

    },
    volumetricPlots() {
      let vol_plots = this.layerSets;
      if (this.sceneViewCompositionMode && this.insightLayers.length > 0 && this.insightLayers[0].layerSetAssets[0].simulationResult.scenario === this.selectedScenario) {
        vol_plots = this.insightLayers.concat(vol_plots);
        vol_plots = vol_plots.filter((layer, i, arr) => arr.map(l => l.identifier).indexOf(layer.identifier) === i);
      }
      return vol_plots.filter(layer => layer.layerType === 'Volumetric');
    },
    canAddScenario() {
      return (this.$store.getters['allowedPermissions'] || {})[ADD_SCENARIO];
    },
    isProjectInCompany() {
      return this.loggedInUser?.is_superuser || this.selectedProject?.company_id == this.loggedInUser?.company_id;
    },
    companyIsRWDI() {
      return this.userCompany?.is_rwdi;
    },
    isDemoProject() {
      return this.selectedProject?.is_demo_project;
    },
    simulationLabels() {
      if(this.isSplitView || this.sceneViewCompositionMode)
        return this.selectedStudy?.simulation_labels.filter(sim => sim.category === 'RESULT');

      if(this.loggedInUser?.is_superuser || this.selectedProject?.is_demo_project || this.userCompany?.is_rwdi)
        return this.selectedStudy?.simulation_labels;

      if (!this.selectedProject?.studies)
        return [];

      if(!this.isProjectInCompany)
        return this.selectedStudy?.simulation_labels.filter(sim => (sim.category === 'RESULT'));

      if(this.canAddScenario) {
        // 1.	a user has submitter role and subscription assigned: they can see all scenarios (INBOUNDS and RESULTS, MONTHLY and ONE-TIME)
        if (this.loggedInUser?.subscription) {
          return this.selectedStudy?.simulation_labels;
        } else { // 2.	a user has submitter access but no subscription: they can see all completed scenarios and only INBOUNDS ones if they are ONE-TIME
          return this.selectedStudy?.simulation_labels.filter(sim => (sim.category === 'RESULT')).concat(this.selectedStudy?.simulation_labels.filter(sim => (sim.category !== 'RESULT' && sim.subscription_type === 'ONE-TIME')));
        }
      } else { // 3.	a user has Read-Only access: they can see only RESULT scenarios
        return this.selectedStudy?.simulation_labels.filter(sim => (sim.category === 'RESULT'));
      }
    },
    submittedSimulationHasErrors() {
      return this.simulation?.errors?.length > 0 && this.simulation.category === 'SUBMITTED';
    },
    isSimulationInbound() {
      return this.simulation?.category == 'INBOUND';
    },
    canDownloadCombinedData() {
      if (!this.isProjectInCompany) {
        return false;
      }

      if (this.dataAssets.length > 0) {
        return this.hasModuleByName('download combined data');
      }
      return false;
    },
    firstAlphabeticalConfigurationId() {
      return this.updatedSimulationLabels?.slice()
        .sort((first, second) => first.label.localeCompare(second.label))[0]
        ?.configurationId;
    },
    ...mapGetters('project', ['sceneViewCompositionMode', 'selectedStudy', 'selectedProject', 'firstSceneView', 'viewerMode', 'drawingMode', 'isPreviewModeOpen', 'createScenarioStep', 'currentInsights', 'assetUploadUrlAvailable', 'selectedScenarioFromProperties', 'selectedSimulationLabels', 'createInsightStep', 'annotationsCompositionMode', 'currentInsightIndex']),
    ...mapGetters('project/simulationAsset', ['addedLayerSets', 'allLayersetsForSelectedInsightSimulation', 'simulationIsLoading', 'subSurfacesForAddedLayers', 'simulations', 'dataAssets', 'userSelectedLayers', 'simulationsFinishedLoading']),
    ...mapGetters(['loggedInUser', 'userCompany', 'companySubscriptions'])
  },
  methods: {
    isSelected(simulationLabel){
      return simulationLabel.configurationId == this.simulation?.configuration.id;
    },
    handleResultLayersList(resultLayersPresent){
      this.$emit('resultLayersPresent', resultLayersPresent);
    },
    handleGeometryLayersList(geometryLayersPresent){
      this.$emit('geometryLayersPresent', geometryLayersPresent);
    },
    hasModuleByName(moduleName) {
      let companyHasModule = !!this.userCompany?.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());

      let userSubscriptionHasModule = false;
      if (this.loggedInUser.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
        }
      }

      return companyHasModule || userSubscriptionHasModule;
    },
    openDeleteAnnotationTypeModal(annotationTypeId) {
      this.$bvModal.show(`confirm-annotation-type-delete-${annotationTypeId}`);
    },
    toggleWireframe(layer) {
      this.$emit('toggleWireframe', layer.identifier);
    },
    setFilterValue(data) {
      this.$emit('setFilterValue', data);
    },
    clearGlobalFilter(data) {
      this.$emit('clearGlobalFilter', data);
    },
    simulationLoaded(simulationLabel) {
      return this.simulations?.some(simulation => simulation.id === simulationLabel.id);
    },
    scrollToTop() {
      if(this.$refs['layers-panel-content'])
        this.$refs['layers-panel-content'].scrollTop = 0;
    },
    fileUploaded(layerSet) {
      this.geometryLayers.push(layerSet);
      this.toggleGeometryLayerSet(layerSet);
    },
    async fileRemoved(layerRemoved) {
      setTimeout(() => {
        this.geometryLayers = this.geometryLayers.filter(
          (layer) => layer !== layerRemoved
        );
        this.toggle([], [layerRemoved]);
      }, 50);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    //are there any layers of a specific layer_type ('e.g. horizontal) loading?
    resultLayerOfTypeLoading(layerType) {
      return this.layerSets.filter(layer => layer.layerType === layerType).some(layer => layer.isLoading);
    },
    getResultLayersOfType(layerType) {
      if(layerType === 'Horizontal') {
        return this.horizontalPlots;
      } else if(layerType === 'Vertical') {
        return this.verticalPlots;
      } else if(layerType === 'Volumetric') {
        return this.volumetricPlots;
      }
    },
    /* user has toggle a presentation plane on or off */
    onPresentationPlaneToggled(layerType, presentationPlane) {
      const planeToToggle = this.presentationPlanes[layerType].find(plane => plane.name == presentationPlane.name);
      planeToToggle.isVisible = !planeToToggle.isVisible; //mark the visibility of the presentation plane (updates the 'checked' status in the UI)

      this.matchPresentationPlaneVisibilty(layerType);  //toggle the asset visibility of each layer to match the visible presentation planes
    },
    /* turn off all presentation planes at once */
    onClearAllPresentationPlanes(layerType) {
      this.presentationPlanes[layerType].forEach(presentationPlane => {
        presentationPlane.isVisible = false;
      });
      this.matchPresentationPlaneVisibilty(layerType);
    },
    onSelectAllPresentationPlanes(layerType) {
      this.presentationPlanes[layerType].forEach(presentationPlane => {
        presentationPlane.isVisible = true;
      });
      this.matchPresentationPlaneVisibilty(layerType);
    },
    /* Goes through each presentation plane and each asset and toggles the asset on/off according to the status of the presentation planes*/
    matchPresentationPlaneVisibilty(layerType) {
      this.presentationPlanes[layerType].forEach(presentationPlane => {
        this.layerSets.filter(layer => layer.layerType === layerType).forEach(layer => {
          layer.layerSetAssets.forEach(asset => {
            if (asset.simulationResult.subsurface_type === presentationPlane.name) {
              asset.isVisible = presentationPlane.isVisible;
            }
          });
        });

        if(this.sceneViewCompositionMode) {
          this.insightLayers.filter(layer => layer.layerType === layerType).forEach(layer => {
            layer.layerSetAssets.forEach(asset => {
              if (asset.simulationResult.subsurface_type === presentationPlane.name) {
                asset.isVisible = presentationPlane.isVisible;
              }
            });
          });
        }
      });
      this.refreshVisisbleLayers(layerType);
    },
    /* turn the visible layers off and on again so they show the right set of assets */
    refreshVisisbleLayers(layerType) {
      var visibleLayers = this.layerSets.filter(layer => layer.layerType === layerType && layer.isVisible === true);
      if (visibleLayers.length > 0) {
        this.toggle(visibleLayers, null);
      }
      if(this.sceneViewCompositionMode) {
        var visibleInsightLayers = this.insightLayers.filter(layer => layer.layerType === layerType && layer.isVisible === true);
        if (visibleInsightLayers.length > 0) {
          this.toggle(visibleInsightLayers, null);
        }
      }
    },
    /* called when the layer picker modal window is closed with layers selected.  Takes the selected layers from the layer picker and adds them to the layerSets array,
    which is bound to the layers panel UI (doing it this way makes sure that the layer panel only gets updated once when the layer-picker is closed instead of constantly
    updating in the background while the user is interacting with the modal window */
    async onLayersSelected() {
      //concatenate the existing layers in the panel with the newly selected ones.  Creating a new Set ensures no duplicates, and using the spread operator puts the de-duplicated set into a regular array
      this.layerSets = this.concatenateAndDeduplicate(this.layerSets, this.selectedLayerSets);

      if (this.sceneViewCompositionMode) {
        this.setAddedLayerSets({
          addedLayers: this.insightLayers.concat(this.layerSets).filter((layer, i, arr) => arr.map(l => l.identifier).indexOf(layer.identifier) === i),
          simulationId: this.simulationId,
          viewId: this.viewId,
          addedByUser: false});
      } else {
        let onlyNewLayers =  this.layerSets.filter(layerSet => layerSet.showByDefault === false);
        let onBothSets = [];
        this.showByDefaultLayerSets.forEach(layer => {
          this.selectedLayerSets.forEach(selected => {
            if (layer.identifier == selected.identifier) {
              onBothSets.push(layer);
            }
          });
        });

        this.setAddedLayerSets({
          addedLayers: onlyNewLayers.concat(onBothSets),
          simulationId: this.simulationId,
          viewId: this.viewId,
          addedByUser: true});
      }

      this.clearSelectedLayerSets();  //once the layers have been added to the layer panel the selectedLayerSets collection can be cleared for next use

      //set the visibilty of each asset to match what's in the global presentationPlanes collection
      await this.$nextTick();

      let layerTypesInSelection = this.layerSets
        .map(x => x.layerType)
        .filter((value, index, self) => {  //get the unique set from the list
          return self.indexOf(value) === index;
        });

      for (const layerType of layerTypesInSelection) {
        this.matchPresentationPlaneVisibilty(layerType);
      }

      this.modalVisible = false;  //close the modal
      this.saveLayerState(true); // save the state
    },
    /* display the layer picker modal window*/
    showModal() {
      this.modalVisible = true;
    },
    /* toggle on the selected results layer and toggle off all other layers */
    radioToggleResultLayerSet(layer) {
      //if layer.isVisible == false, then we've just toggled it on.  Turn off all other layers and mark this one as visible
      const isHorizontal = String(layer?.layerSetAssets[0]?.simulationResult?.layer_type).toLocaleLowerCase();
      if (!layer.isVisible) {
        this.toggle([layer], this.getResultLayersOfType(layer.layerType));
        if(isHorizontal === 'horizontal') this.$emit('enableProbeMode', true);
      } else { //if layer.isVisible == true, then the selected layer was visible and it's just been turned off so hide it in the viewer
        this.toggle(null, [layer]);
        if(isHorizontal === 'horizontal') this.$emit('enableProbeMode', false);
      }
      this.saveLayerState(true);
    },
    /* toggle on/off a geometry layer */
    toggleGeometryLayerSet(layer) {
      if (layer.isVisible) {
        //layer was visisble and needs to be turned off
        this.toggle(null, [layer]);
      } else {
        //layer wasn't visible and needs to be turned on
        this.toggle([layer], null);
      }
      this.saveLayerState(true);
    },
    /* utility method to toggle a set of layers on or off.  sets IsVisible on the layer and notifies the viewer to show/hide the layers */
    toggle(layersOn, layersOff) {
      const toggleArray = [];

      //process layersOff first in case a layer is being turned off and on again in the same call (this happens in the radio toggle)
      if (layersOff) {
        layersOff.forEach(layer => {
          layer.isVisible = false;
          toggleArray.push(this.createAssetToggleInformation(layer, false));
        });
      }

      if (layersOn) {
        layersOn.forEach(layer => {
          layer.isVisible = true;
          toggleArray.push(this.createAssetToggleInformation(layer, true));
        });
      }
      if (toggleArray.length > 0) {
        this.handleResultLayersList(this.layerSets);
        this.handleGeometryLayersList(this.geometryLayers);
        this.$emit('toggleLayers', toggleArray);
      }
    },
    clearAllLayers() {
      let layersToClear = [];
      if (this.layerSets?.length) {
        for (let layer of this.layerSets) {
          if (layer.isVisible) {  //turn off the layer in the viewer if it's currently displayed
            layersToClear.push(layer);
          }
        }
      }
      if(this.insightLayers?.length) {
        for(let layer of this.insightLayers) {
          if(layer.isVisible) {
            layersToClear.push(layer);
          }
        }
      }
      if (!this.sceneViewCompositionMode) {
        this.userSelectedLayers.removeAll();
      }
      this.toggle(null, layersToClear);
      this.clearAddedLayerSets();
      this.layerSets = [];
      this.saveLayerState(true);
    },
    /* user has deleted a layer from the panel, remove it from the addedlayers collection on the store and then reset the panel list */
    remove(layerSet) {
      if (layerSet.isVisible) {  //turn off the layer in the viewer if it's currently displayed
        this.toggle(null, [layerSet]);

      }
      //tell the store to remove it from the list of added layers.
      this.removeAddedLayerSet({
        simulationId: this.simulationId,
        layerToDelete: layerSet,
        viewId: this.viewId
      });

      //update the layerSets collection in the layer panel so that it gets removed.
      const index = this.layerSets.findIndex(layer => layer.identifier === layerSet.identifier);
      if (index > -1) {
        this.layerSets.splice(index, 1);
      }
    },
    /* formats data for the viewer store's ToggleLayer action */
    createAssetToggleInformation(layerToToggle, toggleOn) {
      return {
        layerToToggle: layerToToggle,
        toggleOn: toggleOn
      };
    },
    /* load any saved data layers.  If none exist, load the default layers */
    async loadDefaultOrSavedResultLayers(defaultLayers) {
      //toggle off all layers before resetting the this.layerSets collection or all references to the displayed layers will be lost
      var toggleOff = this.layerSets;
      var toggleOn = [];

      const savedLayersKey = `project:${this.projectId},study:${this.studyId},simulation:${this.simulationId}`;
      const projectSavedLayers = _.cloneDeep(this.savedLayers[savedLayersKey]);

      if (projectSavedLayers) {  //load the saved result layers (from last time the configuration was selected) into the layer panel.
        this.layerSets = projectSavedLayers.nonGeometryLayers;
        this.setAddedLayerSets({
          addedLayers: this.layerSets,
          simulationId: this.simulationId,
          viewId: this.viewId,
          addedByUser: false});

        //if there are layers, toggle on the first one of each layer_type marked as isVisible == true (there should only be 1)
        if (this.layerSets) {
          if (this.horizontalPlots.length > 0)   {
            const firstVisibleLayer = this.horizontalPlots.find(layer => layer.isVisible);
            if (firstVisibleLayer) {
              toggleOn.push(firstVisibleLayer);
            }
          }
          if (this.verticalPlots.length > 0)   {
            const firstVisibleLayer = this.verticalPlots.find(layer => layer.isVisible);
            if (firstVisibleLayer) {
              toggleOn.push(firstVisibleLayer);
            }
          }
          if (this.volumetricPlots.length > 0)   {
            const firstVisibleLayer = this.volumetricPlots.find(layer => layer.isVisible);
            if (firstVisibleLayer) {
              toggleOn.push(firstVisibleLayer);
            }
          }
        }

        //also restore the saved presentation layers
        this.presentationPlanes = projectSavedLayers.presentationPlanes;
      } else {  //load the default result layers into the layer panel.
        this.presentationPlanes = null;

        let layersToDisplay = null;
        if (this.userSelectedLayers) {
          layersToDisplay = this.userSelectedLayers.filterUserSelectedLayers(
            this.simulationId,
            this.allLayerSets,
            this.viewId);
        } else {
          layersToDisplay = [];
        }

        if (layersToDisplay.length > defaultLayers.length) {
          this.layerSets =  layersToDisplay.filter(layerSet => layerSet.showByDefault === false);
          let onBothSets = [];
          let allSelected = this.userSelectedLayers.userSelectedLayers[this.simulationId];
          defaultLayers.forEach(layer => {
            allSelected.forEach(selected => {
              if (layer.identifier == selected.identifier) {
                onBothSets.push(layer);
              }
            });
          });
          toggleOff = toggleOff.concat(defaultLayers);

          this.layerSets = _.cloneDeep(this.concatenateAndDeduplicate(this.layerSets, onBothSets));
        } else {
          if (this.userSelectedLayers) {
            this.layerSets = _.cloneDeep(defaultLayers);
          } else {
            this.layerSets = [];
          }
        }

        this.setAddedLayerSets({
          addedLayers: this.layerSets,
          simulationId: this.simulationId,
          viewId: this.viewId,
          addedByUser: false});

        //load the first layer in each "layer type" group
        if (this.layerSets) {
          if (this.horizontalPlots.length > 0)   {
            toggleOn.push(this.horizontalPlots[0]);
          }
          if (this.verticalPlots.length > 0)   {
            toggleOn.push(this.verticalPlots[0]);
          }
          if (this.volumetricPlots.length > 0)   {
            toggleOn.push(this.volumetricPlots[0]);
          }
        }
      }
      setTimeout(() => {
        this.toggle(toggleOn, toggleOff);
      }, 0);
    },
    /* load any saved geometry layers.  If none exist, load the default layers */
    loadDefaultOrSavedGeometryLayers(defaultLayers) {
      var toggleOff = [].concat(this.geometryLayers);
      var toggleOn = [];

      const savedLayersKey = `project:${this.projectId},study:${this.studyId},simulation:${this.simulationId}`;
      const projectSavedLayers = this.savedLayers[savedLayersKey];

      if (projectSavedLayers) {  //load the saved geometry layers into the layer panel.
        this.geometryLayers = projectSavedLayers.geometryLayers;
        if (this.geometryLayers && this.geometryLayers.length > 0) {
          this.geometryLayers.filter(layer => layer.isVisible).forEach(layer => {
            toggleOn.push(layer);  //turn on anything marked as isVisible == true
          });
        }
      } else {  //load the default geometry layers into the layer panel.
        this.geometryLayers = defaultLayers;
        if (this.isPreviewModeOpen) {
          //when in scenario preview mode, show all geometry assets by default
          this.geometryLayers.forEach(layer => {
            toggleOn.push(layer);
          });
        } else {
          //not confitming a new scenario so toggle anything marked as a default layer
          this.geometryLayers.forEach(layer => {  //turn on anything marked as 'Show by default"
            if (layer.showByDefault === true) {
              toggleOn.push(layer);
            }

          });
        }
      }
      // delay toggling layers since this occurs at the same time as the result layers are loaded
      setTimeout(() => {
        this.toggle(toggleOn, toggleOff);
      }, 0);
    },
    mergeSubSurfaces(newValue) {
      if (!newValue || Object.keys(newValue).length === 0) {
        return null;
      }

      if (!this.presentationPlanes) {
        this.presentationPlanes = {};
      }

      Object.keys(newValue).forEach(layerType => {
        let currentLayerPlots = {};
        if (layerType == 'Horizontal') {
          currentLayerPlots = this.horizontalPlots;
        } else if (layerType == 'Vertical') {
          currentLayerPlots = this.verticalPlots;
        } else if (layerType == 'Volumetric') {
          currentLayerPlots = this.volumetricPlots;
        }
        let sameRankPresentationPlanes = {};
        currentLayerPlots.forEach(layerset => {
          layerset.layerSetAssets.forEach(asset => {
            sameRankPresentationPlanes[asset.simulationResult.subsurface_type] = this.rank ;
          });
        });

        const presentationPlanesForLayerType = this.presentationPlanes[layerType];
        if (presentationPlanesForLayerType) {
          //layer type exists
          newValue[layerType].forEach(newPresentationPlane => {
            const matchingPresentationPlane = presentationPlanesForLayerType.find(plane => plane.name == newPresentationPlane);
            if (!matchingPresentationPlane) {
              //doesn't exist, add it
              if (sameRankPresentationPlanes[newPresentationPlane]) {
                //if the new PP it's from the same rank, add it
                this.presentationPlanes[layerType].push(new PresentationPlane(newPresentationPlane, true));
              }
            }
          });

          presentationPlanesForLayerType.forEach((oldPresentationPlane, index) => {
            const matchingPresentationPlane = newValue[layerType].find(newPresentationPlane => oldPresentationPlane.name == newPresentationPlane);
            if (!matchingPresentationPlane) {
              if (!sameRankPresentationPlanes[oldPresentationPlane.name]) {
                //a presentation plane in the old set has been removed
                this.presentationPlanes[layerType].splice(index, 1);
              }
            }
          });
        } else {
          //layer type doesn't exist, add it
          this.presentationPlanes[layerType] = newValue[layerType].map(name => new PresentationPlane(name, true));
        }
      });
    },
    concatenateAndDeduplicate(layerSet1, layerSet2) {
      let mergedArray = [];
      layerSet1.forEach(layer => {
        if (!mergedArray.some(entry => entry.identifier === layer.identifier)) {
          mergedArray.push(layer);
        }
      });
      layerSet2.forEach(layer => {
        if (!mergedArray.some(entry => entry.identifier === layer.identifier)) {
          mergedArray.push(layer);
        }
      });

      return mergedArray;
    },
    toggleNav() {
      this.$emit('toggleLayerPanel');
    },
    async setScenario(simulation, index) {
      this.clearGraphMode();
      this.selectedScenario = simulation;
      await this.saveLayerState(true);
      this.clearSelectedLayerSets();
      this.setGeometryFilesUploaded(null);
      this.$emit('selectSimulation', index);
      this.$emit('selectedSimulationLabel', simulation);
      if (this.isSimulationInbound) {
        this.setInboundScenario({ simulation, index });
        this.buildFormValues(this.simulation.assets);
      }
      if (this.viewerMode == 'Viewer') {
        if(this.rank === 'primary') {
          this.$router.push({
            name: 'ViewerContainer',
            params: { study: this.studyId, configuration: index },
            ...(this.$route.query?.secondary && { query: { secondary: this.$route.query?.secondary }})
          });
        } else if (this.rank === 'secondary') {
          this.$router.push({ path: this.$route.path, query: { secondary: index }});
        }
      }
      this.forceRerender();
      if(this.sceneViewCompositionMode) {
        this.clearAllLayers();
      }
    },
    /* this is an event set from the AppContainer, via the store, when a new configuration is selected.  This tells the layer panel to save it's state */
    async saveLayerState(shouldSaveState) {
      if (shouldSaveState && !this.sceneViewCompositionMode) {
        await this.$emit('saveLayers', {
          studyId: this.studyId,
          projectId: this.projectId,
          simulationId: this.simulationId,
          geometryLayers: this.geometryLayers,
          nonGeometryLayers: this.layerSets,
          presentationPlanes: this.presentationPlanes });
        await this.updateLayerState(false);
      }
    },
    getInitialSimulation() {
      const study = this.$store.getters['project/studies']?.find(study => study.id === Number(this.$route.params.study));
      let initialSimulation;
      if(this.rank === 'primary') {
        initialSimulation = study?.simulation_labels?.find(simulationlabel => simulationlabel.configurationId === Number(this.$route.params.configuration));
      } else if (this.rank === 'secondary') {
        initialSimulation = study?.simulation_labels?.find(simulationlabel => simulationlabel.configurationId === Number(this.$route.query?.secondary));
      }
      this.selectedScenario = initialSimulation?.label;
      this.$emit('selectSimulation', initialSimulation.configurationId);
      this.$emit('selectedSimulationLabel', this.selectedScenario);
    },
    getInitialSimulationFromUpdatedList() {
      const initialSimulation = this.updatedSimulationLabels?.find(simulationlabel => simulationlabel.configurationId === this.firstAlphabeticalConfigurationId);
      this.selectedScenario = initialSimulation?.label;
      this.$emit('selectedSimulationLabel', this.selectedScenario);
    },
    buildFormValues(assets) {
      this.theFormValues = {};
      let studyFiles = [];
      let surroundsFiles = [];
      let groundFiles = [];
      let presentationPlaneFiles = [];
      let landscapingFiles = [];
      let mitigationsFiles = [];
      let overlayFiles = [];

      for (let asset of assets) {
        if (asset.geometry_type == 'study') {
          studyFiles.push(this.getFile(asset));
        } else if (asset.geometry_type == 'surround') {
          surroundsFiles.push(this.getFile(asset));
        } else if (asset.geometry_type == 'ground') {
          groundFiles.push(this.getFile(asset));
        } else if (asset.geometry_type == 'presentation plane') {
          presentationPlaneFiles.push(this.getFile(asset));
        } else if (asset.geometry_type == 'landscaping') {
          landscapingFiles.push(this.getFile(asset));
        } else if (asset.geometry_type == 'mitigation') {
          mitigationsFiles.push(this.getFile(asset));
        } else if (asset.geometry_type == 'overlay') {
          overlayFiles.push(this.getFile(asset));
        }

        this.geometryFileUploaded(new Asset(asset));
      }

      this.theFormValues = {
        'study-file': studyFiles,
        'surrounds-file': surroundsFiles,
        'ground-file': groundFiles,
        'presentation_plane-file': presentationPlaneFiles,
        'landscaping-file': landscapingFiles,
        'mitigations-file': mitigationsFiles,
        'overlay-file': overlayFiles,
      };
    },
    getFile(asset) {
      return {
        url: asset.asset_file,
        name: asset.filename,
        file: asset.filename,
        asset_id: asset.id,
        metaData: asset.meta_data || {},
        asset: new Asset(asset),
        uploadComplete: true
      };
    },
    getUpdatedSimulationLabels() {
      if (this.selectedSimulationLabels.length == 0)
        this.updatedSimulationLabels = this.simulationLabels;
      else
        this.updatedSimulationLabels = this.selectedSimulationLabels.filter(simulationLabel => this.simulationLabels.map(a => a.configurationId).includes(simulationLabel.configurationId));
    },
    multiScreenshots(layer, currentIndex, list, type){
      if(!this.multipleScreenshots) {
        this.setLoadingMultipleScreenshots(false);
        return;
      } else {
        setTimeout(() => {
          if (this.$store.getters['project/simulationAsset/layerIsLoading']) {
            this.multiScreenshots(layer, currentIndex, list, type);
          } else if (!this.$store.getters['project/simulationAsset/layerIsLoading'] && layer.isVisible) {
            if(this.multipleScreenshots) {
              this.$root.$emit('takeScreenshot',`${type}__${layer.abbreviatedLabel.split(' · ').join('-').split(' ').join('_')}`);
            }
            setTimeout(() => {
              if (currentIndex < list.length - 1) {
                this.radioToggleResultLayerSet(list[currentIndex+1]);
                setTimeout(() => {
                  this.multiScreenshots(list[currentIndex+1], currentIndex+1, list, type);
                }, 1000);

              } else {

                if (type == 'horizontal') {
                  this.radioToggleResultLayerSet(layer);
                  setTimeout(() => {
                    if (this.volumetricPlots.length > 0) {
                      this.radioToggleResultLayerSet(this.volumetricPlots[0]);
                      setTimeout(() => {
                        this.multiScreenshots(this.volumetricPlots[0], 0, this.volumetricPlots, 'volumetric');
                      }, 1000);
                    } else {
                      this.$emit('takeMultipleScreenshots', false);
                      this.setLoadingMultipleScreenshots(false);
                    }
                  }, 1000);
                } else {

                  this.radioToggleResultLayerSet(layer);
                  this.$emit('takeMultipleScreenshots', false);
                  this.setLoadingMultipleScreenshots(false);

                }

              }
            }, 1000);
          } else {
            this.multiScreenshots(layer, currentIndex, list, type);
          }
        }, 1000);
      }
    },
    clearGraphMode() {
      this.$emit('clearProbes');
      this.$emit('setGraphRequiresProbes', false);
    },
    ...mapActions({
      updateLayerState: 'project/viewer/saveLayerState',  //SaveLayerState getter used as an event to tell the panel to save it's layers.  This allows the panel to reset the 'SaveLayerState' property back to 'false' after saving is complete
      removeAddedLayerSet: 'project/simulationAsset/removeAddedLayerSet',  //user has deleted a layer from the panel, notify the store and remove from Layers
      setAddedLayerSets: 'project/simulationAsset/setAddedLayerSets',  //informs the store what assets are currently on display in the layer panel
      clearAddedLayerSets: 'project/simulationAsset/clearAddedLayerSets',  //clears all layer sets on the store that are marked as "added to the layer panel"
      clearSelectedLayerSets: 'project/simulationAsset/clearSelectedLayerSets',  //clears the list of selected assets from the layer picker
      geometryFileUploaded: 'project/viewer/geometryFileUploaded', //Used to notify the store (and other components) that there are geometry files that have been uploaded (i.e. dropped on the upload control)
      setInboundScenario: 'project/setInboundScenario', //Set a scenario to inbound
      getProjectAssetUploadUrl: 'project/getProjectAssetUploadUrl', //Get project asset upload url
      storeUpdateScenario: 'project/updateScenario', //Used to update scenario
      setGeometryFilesUploaded: 'project/viewer/setGeometryFilesUploaded', //Used to set the geometryFilesUploaded flag
      setAbortLoadFlag: 'project/viewer/setAbortLoadFlag',
      resizeViewerCanvas: 'project/viewer/setWindowResizeRequired', //Force canvas to resize when collapse sidebar
      setLoadingMultipleScreenshots: 'project/viewer/setLoadingMultipleScreenshots'
    })
  },
  watch: {
    insightLayers(newList, oldList) {
      //this is kind of hacky, but when sceneViewCompositionMode is active and `newList` and `oldList` are both not empty is when the global filter changes because the
      //user selected some different filter options in the layer picker while in scene composition mode.  When this happens the isVisible
      //value of the old layerset is lost and it becomes unchecked.  Here we check if it was visible when the collection is reset and if so
      // set it to visible again on the new collection
      if (newList.length > 0 && oldList.length > 0 && this.sceneViewCompositionMode) {
        for(let newLayer of newList) {
          let matchingOldValue = oldList.find(layer => layer.identifier == newLayer.identifier);
          if (matchingOldValue) {
            newLayer.isVisible = matchingOldValue.isVisible;
          }
        }
      }
    },
    async sceneViewCompositionMode(newValue) {
      if(newValue) {
        let currentInsight = this.currentInsights[Number(this.$route.query.page)];
        let currentConfigId = null;
        if (currentInsight.sceneview_set[0]) {
          currentConfigId = currentInsight.sceneview_set[0].configuration_id;
        } else {
          currentConfigId = Number(this.$route?.params?.configuration);
        }

        let currentScenario = this.selectedStudy.simulation_labels.find(sim => sim.configurationId == currentConfigId);
        // change scenario to result scenario if not already
        if(currentScenario.category != 'RESULT') {
          let firstResultScenario = this.updatedSimulationLabels.find(sim => sim.category == 'RESULT');
          this.selectedScenario = firstResultScenario.label;
          this.$emit('selectedSimulationLabel', this.selectedScenario);
          await this.setScenario(firstResultScenario.label, firstResultScenario.configurationId);
        } else {
          this.selectedScenario = currentScenario.label;
          this.$emit('selectedSimulationLabel', this.selectedScenario);
          await this.setScenario(currentScenario.label, currentScenario.configurationId);
        }
        this.geometryLayers = this.geometryLayerSets.filter(layer => layer.identifier !== 'presentation plane');
        this.toggle(this.geometryLayers.concat(this.insightLayers), null);
        this.setAddedLayerSets({
          addedLayers: this.insightLayers,
          simulationId: this.simulationId,
          viewId: this.viewId,
          addedByUser: false});

        this.resizeViewerCanvas(true);
      } 
    },
    presentationPlanes(){
      if(this.horizontalPlots != null && this.horizontalPlots.length > 0 && !this.simulationIsLoading){
        this.$emit('enableProbeMode', true);
      }

      if(this.sceneViewCompositionMode) {
        const activeInsightLayers = this.currentInsight?.sceneview_set?.[0]?.active_layers || null;
        if(activeInsightLayers) {
          let assetFilesNoUnderscores = activeInsightLayers.map(layer => layer.asset_file.replace(/_/g, ' '));
          let assetFiles = activeInsightLayers.map(layer => layer.asset_file);
          if(this.presentationPlanes && this.presentationPlanes['Horizontal']?.length > 1) {
            this.presentationPlanes['Horizontal'].forEach(pp => {

              if(assetFilesNoUnderscores.some(file => file.includes(pp.name)) || assetFiles.some(file => file.includes(pp.name)) || this.simulation.configuration.id !=  this.currentInsight?.sceneview_set?.[0].configuration_id) {
                pp.isVisible = true;
              } else {
                pp.isVisible = false;
              }
            });
          }
          if(this.presentationPlanes && this.presentationPlanes['Vertical']?.length > 1) {
            this.presentationPlanes['Vertical'].forEach(pp => {
              if(assetFilesNoUnderscores.some(file => file.includes(pp.name)) || assetFiles.some(file => file.includes(pp.name)) || this.simulation.configuration.id !=  this.currentInsight?.sceneview_set?.[0].configuration_id ) {
                pp.isVisible = true;
              } else {
                pp.isVisible = false;
              }
            });
          }
          if(this.presentationPlanes && this.presentationPlanes['Volumetric']?.length > 1) {
            this.presentationPlanes['Volumetric'].forEach(pp => {
              if(assetFilesNoUnderscores.some(file => file.includes(pp.name)) || assetFiles.some(file => file.includes(pp.name)) || this.simulation.configuration.id !=  this.currentInsight?.sceneview_set?.[0].configuration_id) {
                pp.isVisible = true;
              } else {
                pp.isVisible = false;
              }
            });
          }
        }
      }
    },
    $route (to){
      if (to.query.page)
        this.$store.dispatch('project/switchInsight', Number(to.query.page));

      if (this.studyId !== parseInt(to.params.study)) {
        this.studyId = parseInt(to.params.study);
        this.getInitialSimulation();
      }
    },
    selectedScenarioFromProperties(newValue) {
      if (newValue) {
        this.clearAllLayers();
        this.setScenario(newValue.label, newValue.configurationId);
        this.resizeViewerCanvas(true);
      }
    },

    /* Triggered when the selected configuration changes (including first load). The simulationAssets (in the store) get set this allows the layer panel to load the default layers if no other layers are saved from previously navigating
    to this configuration.  watching the assets directly (and not the showByDefaultLayerSets) means this only fires when the underlying default assets changes, not when the global filter changes (which affects the layer sets since the
    global filter is used to in their group-by) */
    async defaultAssets() {
      if (this.viewerMode == 'Viewer') {  //only show result layers when in 'Viewer' mode, not in 'Insight' mode
        await this.loadDefaultOrSavedResultLayers(this.showByDefaultLayerSets);
      }
    },
    /* Triggered when the selected configuration changes (including first load).  When the geometryLayerSets (in the store) changes, set the geometryLayers list, which is iterated over in the template to list out the geometry asset layers that
    can be toggle on.  Similar to above, this is watched instead of the layer set collection to ensure the code is only triggered when the underlying assets change, not when the global filter changes */
    geometryAssets() {
      if (this.viewerMode == 'Viewer' || this.sceneViewCompositionMode) {
        this.loadDefaultOrSavedGeometryLayers(this.geometryLayerSets);  //put the geometry layers into the layers panel
      }
    },
    /* When the ViewerMode changes, toggle the InsightLayers and result layers accordingly */
    async viewerMode(newValue) {
      if(newValue === 'Viewer') {
        this.setAbortLoadFlag(true);

        // this.clearAddedLayerSets();
        await this.getInitialSimulation();
        await this.$store.dispatch('project/simulationAsset/selectSimulation', Number(this.$route?.params?.configuration));
        await this.loadDefaultOrSavedGeometryLayers(this.geometryLayerSets);
        await this.loadDefaultOrSavedResultLayers(this.showByDefaultLayerSets);
      }

      if (newValue === 'Insights') {
        //the layer panel needs to turn off it's geometry layers and the insight title component will turn on the ones related to the current insight
        this.toggle(null, this.layerSets.concat(this.geometryLayerSets));
        await this.$nextTick();
      }
    },
    subSurfacesForAddedLayers(newValue) {
      this.mergeSubSurfaces(newValue);
    },
    projectId(newValue) {
      if (!newValue) {
        return;
      }
      this.getProjectAssetUploadUrl(newValue);
    },
    async simulation(newValue) {
      if (this.sceneViewCompositionMode) {
        this.presentationPlanes = null;
        await this.$nextTick();
      }

      if (newValue && this.isSimulationInbound ) {
        this.buildFormValues(newValue.assets);
      } else if (newValue && this.submittedSimulationHasErrors) {
        this.buildFormValues(newValue.assets);
      }
    },
    createScenarioStep() {
      this.scrollToTop();
    },
    multipleScreenshots(newValue) {
      setTimeout(() => {
        if (newValue && this.horizontalPlots.length > 0) {
          this.setLoadingMultipleScreenshots(true);
          setTimeout(() => {
            this.toggle(null,this.horizontalPlots);
            this.toggle(null,this.volumetricPlots);
            this.radioToggleResultLayerSet(this.horizontalPlots[0]);
            setTimeout(() => {
              this.multiScreenshots(this.horizontalPlots[0], 0, this.horizontalPlots, 'horizontal');
            }, 1000);
          }, 500);
        } else if (newValue && this.volumetricPlots.length > 0) {
          this.setLoadingMultipleScreenshots(true);
          setTimeout(() => {
            this.toggle(null,this.horizontalPlots);
            this.toggle(null,this.volumetricPlots);
            this.radioToggleResultLayerSet(this.volumetricPlots[0]);
            setTimeout(() => {
              this.multiScreenshots(this.volumetricPlots[0], 0, this.volumetricPlots, 'volumetric');
            }, 1000);
          }, 500);
        } else {
          this.$emit('takeMultipleScreenshots', false);
        }
      }, 1000);
    }
  }
};
</script>

<style>
/* needs to be global to override bootstrap defaults on tab header */
.no-underline {
  text-decoration: none !important;
}

.scenario-dropdown-menu {
  max-height: 50vh !important;
}

</style>
<style scoped>
.scenario-dropdown {
  height: 2.375rem;
}


.mr-tiny {
  margin-right: 0.15rem;
}
.insight-description {
  overflow-y: auto;
}

.insight-annotations {
  margin-bottom: 51.6px;
}

.insight-annotations-container {
  max-height: 34.25rem;
}

.annotation-title {
  padding: 0;
  color: var(--grey-900);
  font: normal normal bold 1.313rem/1.563rem Inter;
  opacity: 1;
}

.dummy-annotation-title {
  color: var(--grey-600);
  font-size: 0.9rem !important;
  font-weight: normal !important;
}
.annotation-bg-circle {
  width: 22.62px;
  height: 22.62px;
}

.annotation-icon {
  width: 16px;
  height: 16px;
  padding: 3.31px;
  vertical-align: top;
}
.annotation-tag {
  color: white;
  font-family: Tahoma;
  width: inherit;
  line-height: 22.62px;
  text-align: center;
}
.category-title {
  display: flex;
  align-items: baseline;
  text-indent: 1em;
}

.horizontal-category:before {
  content: "";
  background: url('~@/assets/svg/icon-horiz-grey.svg') no-repeat;
  width: 1.25rem;
  height: 1.25rem;
  float: left;
  position: relative;
  top: 1.375rem;
}

.insight-header-container {
  padding: 0.75rem;
  height: 2.375rem;
}

.vertical-category:before {
  content: "";
  background: url('~@/assets/svg/icon-vert-grey.svg') no-repeat;
  width: 1.25rem;
  height: 1.25rem;
  float: left;
  position: relative;
  top: 1.313rem;
}

.volumetric-category:before {
  content: "";
  background: url('~@/assets/svg/icon-vol-grey.svg') no-repeat;
  width: 1.25rem;
  height: 1.25rem;
  float: left;
  position: relative;
  top: 1.25rem;
}

.layers-panel h3 {
  font-size: 0.925rem;
  color: var(--grey-900);
  font-weight: 660;
}

.layers-panel h4 {
  font-size: 1.25em;
}

.layers-panel h5 {
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
}

.navigation-button-container {
  margin-top: 0;
  clear: both;
  background: var(--grey-200);
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.delete-insight-container {
  margin-top: 0;
  clear: both;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 5%;
}

.button-container {
  position: sticky;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.layers-panel-content {
  overflow-y: auto;
  height: calc(100% - 6.5rem);
  padding: 1rem;
}

.add-button {
  width: 3rem;
  border: none;
}

.insight-title-bar-button {
  width: 1.5rem;
  min-width: 1.5rem;
  cursor: pointer;
}

.add-button-single-tab {
  width: 3rem;
  border: none;
  margin-top: -2.5rem;;
}

h2 {
  color: var(--grey-900);
  font: normal normal bold 1.313rem/1.563rem Inter;
  opacity: 1;
}

p {
  color: var(--grey-900);
  font: normal normal normal 0.875rem/1.063rem Inter;
}

button {
  width: 25%;
  margin: 0;
  padding: 0.5em 0.75em;
  font-size: 0.9rem;
}

.layers-header-single-tab {
  margin-left: -1rem;
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
}

.visibility-button {
  width: 3rem;
  color: #0173B9;
}

button {
  padding: 0;
  font-size: 0.75em;
  font-weight: 700;
}

.pin-type-visibility {
  z-index: 1;
}

.pin-type-visibility:hover {
  cursor: pointer;
}

.toggle-sidebar-button:hover {
  background-color: #dbdbdb;
  color: #303030;
}

.closed-layers-panel:hover {
  background-color: #dbdbdb;
  color: #303030;
  cursor: pointer;
}

.closed-layers-panel {
  background-color: var(--grey-100);
  width: 0.938rem;
  min-width: 0.938rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.toggle-sidebar-button {
  color: #666;
  background-color: #f0f0f0;
  left: 0;
  height: 1.313rem;
  transition: width 0.3s;
  padding: 0 1rem;
  border: 0;
  display: flex;
  align-items: center;
  border-top: 0.063rem solid #dbdbdb;
  position: relative;
  bottom: 2.35em;
  left: 0;
}

.active {
  display: block;
}

.fine-text {
  font-size: 0.75rem;
}

.scenario {
  margin-left: 0.75rem;
}

.dd {
  max-width: 9.375rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-button {
  width: calc(100% - 3rem);
}

.scenario-selection-container {
  background-color: var(--grey-400);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  height: 2.375rem;
}

.btn-group {
  margin-top: 0;
}

.formulate-input .formulate-input-label {
    font-weight: 700;
}

.scenario-dropdown /deep/ .dropdown-menu {
  overflow-y: scroll;
  max-height: 37.5rem;
}

.create-as-a-draft .custom-control-input {
  position: absolute;
}

.insight-form-label {
  font-family: Inter;
  font-size: 0.89rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
}

.gray-button {
  background-color: var(--grey-500);
}

.gray-button:hover {
  background-color: var(--grey-500);
}

.gray-button:focus {
  background-color: var(--grey-500);
}

.gray-button:active {
  background-color: var(--grey-500);
}
</style>

<style>  /* style overrides for modal window when inside the layer panel */
#layers-panel .modal-container .content-container {
  padding: 0;
  overflow: hidden; /* to make sure rounded corners are visible */
  max-height: calc(100vh - 6em);
  min-height: 25rem;
  max-width: calc(100vw - 6em);
  min-width: 25rem;
  height: 100%;
}

#layers-panel .main-content {
  height: 100%;  /* need to specify height: 100% on this container to ensure that a child in the layer-picker can use height: calc(100% - xyz); */
}
</style>